/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/greensock@1.20.2/dist/TweenLite.js
 * - /npm/gsap@3.0.1/dist/gsap.min.js
 * - /npm/gsap@3.0.1/dist/ScrollToPlugin.min.js
 * - /npm/moment@2.22.2/moment.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 * - /npm/bootstrap@4.3.1/dist/js/bootstrap.min.js
 * - /npm/jquery-colorbox@1.6.4/jquery.colorbox.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
